import { model } from '@telekomconsalting/dex-guru-model'
import BigNumber from 'bignumber.js'
import { TransactionReceipt } from 'web3-core'

import { GasFeeType, GasPrice, GasPriceEIP1559, QuoteResponse } from './model'
import { TradeType } from './tokenModel'

export type TransactionId = string

export interface TxnParams {
  from?: string
  to?: string
  data?: string
  gasPrice?: string
  gas?: string
  value?: string
  maxPriorityFee?: string
  maxPriorityFeePerGas?: string
}

export interface LPTokenTransactionModel {
  address: string
  amount: number
  amountNative: number
  amountStable: number
  priceNative: number
  priceStable: number
  symbol: string
}

export interface TransactionModel {
  amountNative?: number | null
  amounts: number[]
  amountsNative?: number[] | null
  amountsStable?: number[]
  amountStable?: number
  fromAddress: model.Address | null
  network: model.TokenNetwork
  poolAddress?: model.Address | null
  pricesNative?: number[] | null
  pricesStable?: number[] | null
  symbols: string[]
  timestamp: number
  toAddress: model.Address | null
  tokenAddresses: model.Address[]
  transactionAddress: TransactionId
  transactionType: string
  type: string
  wallets: string[] | null
  walletsCategories?: model.Category[] | null
  lpToken?: LPTokenTransactionModel
}

// https://app.shortcut.com/dexguru/story/16241/create-feature-flag-for-v3-transactions
export interface TransactionModelV2 {
  id: string
  transactionAddress: model.Address
  timestamp: number
  blockNumber: number
  to: string
  sender: string
  amountUSD: number
  amountETH: number
  amount0In: number
  amount0Out: number
  amount1Out: number
  amount1In: number
  pairAddress: model.Address
  pairLiquidityUSD: number
  pairLiquidityETH: number
  walletAddress: model.Address
  fromWalletAddress: model.Address
  toWalletAddress: model.Address
  walletCategory: string
  AMM: string
  network: model.TokenNetwork
  transaction_type: string
  // swap
  token0Address: model.Address
  token1Address: model.Address
  token0Symbol: string
  token1Symbol: string
  token0PriceUSD: number
  token1PriceUSD: number
  token0PriceETH: number
  token1PriceETH: number
  // transfer
  symbol: string
  tokenAddress: model.Address
  amount: number
  // burn, mint
  amount0: number
  amount1: number
  amount0USD: number
  amount1USD: number
  amount0ETH: number
  amount1ETH: number
}

export interface Transaction {
  amount?: BigNumber
  approvalInProgress: boolean | null
  balanceHasChanged: boolean
  error?: string
  estimatedAmount?: BigNumber
  estimatedGasAmount?: BigNumber
  estimateLoading: boolean | null
  fromTokenAddress?: model.Address
  gasCosts?: BigNumber
  gasPrice?: GasPrice | GasPriceEIP1559
  hashTxn?: string
  isEnoughForGas?: boolean
  isLoading: boolean
  price?: BigNumber
  quoteResponse?: QuoteResponse
  slippage?: number
  sourcesString: string
  tip?: number
  tokenNetwork?: model.TokenNetwork
  toTokenAddress?: model.Address
  txnError?: TxnError
  txnParams: TxnParams
  type: TradeType
  receipt?: TransactionReceipt
}

export interface StatefulTransaction extends Transaction {
  txnVerificationComplete: boolean
  txnVerificationInProgress: boolean
  txnVerificationSuccess: boolean
  isNewTxn: boolean
  lastRequest: string
  account?: model.Address | null
  tradeId?: string
  gasFeeType?: GasFeeType
  isPostSubmissionFailure: boolean
}

export type TxnError = {
  message: string
  zeroXRequestUrl?: string
  originalError?: string
  kind?: ErrorKind
}

export enum ErrorKind {
  zeroX = '0x',
  rpc = 'RPC',
  dex = 'dex.guru',
}

import { model } from '@telekomconsalting/dex-guru-model'
import BigNumber from 'bignumber.js'

import { Currency } from './model'

export type TradeType = 'Buy' | 'Sell'
export type TradeformType = 'market' | 'limit'
export type TokenV2 = model.TokenV2
export type TokenV3 = model.TokenV3
export type TokenId = model.TokenId
export type TokenStaticV3 = model.TokenStaticV3
export type TokenStaticV2 = model.TokenStaticV2
export type TokenNetwork = model.TokenNetwork
export type NetworkConfigV3 = model.NetworkConfigV3
export type Address = model.Address
export type AMM = model.AMM
export type TokenHoldersCategoryName = model.TokenHoldersCategoryName
export type Category = model.Category

interface TrendingTokenBase {
  id: model.TokenId
  network: model.TokenNetwork
  symbol: string
  tokenAddress: model.Address
  heavyTradersCOT: number
  current: {
    priceUSD: number
    volumeUSD: number
    priceETH: number
    volumeETH: number
  }
  trending: {
    day: {
      priceMove: number
      volumeMove: number
    }
    week: {
      priceMove: number
      volumeMove: number
    }
    month: {
      priceMove: number
      volumeMove: number
    }
  }
  chart: {
    volume: number
    price: number
    timestamp: number
  }[]
}

export type TrendingTokenV2 = TrendingTokenBase & { logoURI: string; symbol: string }
export type TrendingTokenV3 = TrendingTokenBase & { logoURI: string[]; symbols: string[] }

export interface TokenPrice {
  address: model.Address
  token_price_usd: number
  token_price_eth: number
}

export interface UpdateTokenPrice {
  address: model.Address
  token_price: number
  currency: Currency
}

export interface TopCryptosToken extends model.TokenV3 {
  tokenListsNames: string[]
  marketCap: number
  marketCapChange24h: number
  changePrice7days: number
  chartPriceUSDChange7d: {
    timestamp: number
    price: number
    volume: number
  }[]
}
export interface ApprovalContext {
  tokenAllowance?: BigNumber | null
  isTokenApproved?: boolean | null
  approvalComplete?: boolean | null
  tokenApproveError?: string | null
}
export interface TokenWithApprovalContext extends model.TokenV3 {
  zeroX?: ApprovalContext
  oneInch?: ApprovalContext
  error?: Error
}

export interface TokenBalance {
  balance: BigNumber
  value: BigNumber
  network: string
  id: string
}

export enum MarketType {
  lp = 'lp',
  token = 'token',
  account = 'account',
}

export interface TraderBalanceRowData {
  tokenID: model.TokenId
  network: model.TokenNetwork
  balance: number
  balanceUSD: number
  change7Days: number
  inAmountWeekSum: number
  outAmountWeekSum: number
  dealsAVGPrice: number
  marketPrice: number
  profit: string
  logoURI: string[]
  symbols: string[]
  marketType: MarketType
  amm: string | null
  addresses: model.Address[]
  detail: string
}
